#img-block {
    width: 100%;
    height:100%;
    background-size: cover;
    overflow: hidden;
    /* float:left; */
}

#img-block img {
    -o-transition: all 1s;
    -webkit-transition: all 1s;
    transition: all 1s;
}