#icon-block {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #bc0000;
    overflow: hidden;
    float: left;
}

#icon-block img {
    height: 70%;
    margin: .7em;
}
